<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">약관 {{ viewModel.isModify ? '수정' : '등록' }}</strong>
        </div>
        <div class="area_right">
          <SwitchCheck
            :value.sync="viewModel.isDiffMode"
            :isSwitchLeft="false"
            text="최신 배포완료 약관과 비교 모드"
            @update:value="viewModel.setDiffContent()" />
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>불러오기</th>
          <td>
            <div class="group_form">
              <Selectbox
                :dataList="viewModel.copyDataList"
                :value.sync="viewModel.copyTermId"/>
              <Button
                btnSize="small"
                btnStyle="secondary"
                :disabled="!viewModel.copyTermId"
                text="불러오기"
                @onClickBtn="viewModel.onClickCopyTerm()"/>
            </div>
          </td>
        </tr>
        <tr>
          <th>이용약관 본문<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td class="td_content">
            <div class="inner_td">
              <div v-if="viewModel.isDiffMode" class="area_diff">
                <strong class="tit_diff">이전 버전 약관에서의 변경사항 확인하기(기능수정중)</strong>
                <div
                  class="inner_diff"
                  v-html="viewModel.diffNpmData"></div>
              </div>
              <EditorTiptap
                :value.sync="viewModel.lastDepolyTermContent"
                :menuDataList="['bold','heading1','heading2','heading3','bulletList','orderedList']"
                @update="viewModel.onUpdateEditor()"/>
              <!-- <div class="area_oldval screen_out" v-html="viewModel.lastDepolyTermContent"></div>
              <div class="area_newval screen_out" v-html="viewModel.model.content"></div> -->

              <!-- <div v-if="viewModel.isDiffMode" class="area_diff">
                <strong class="tit_diff">신규생성</strong>
                <div
                  class="inner_diff"
                  v-html="viewModel.diffData"></div>
              </div> -->
              <!-- <div v-if="viewModel.isDiffMode" class="area_diff">
                <strong class="tit_diff">이전 버전 약관에서의 변경사항 확인하기(기능수정중)</strong>
                <div
                  id="areaDiff"
                  class="inner_diff"
                  v-html="viewModel.diffData"></div>
                <div class="area_oldval screen_out" v-html="viewModel.lastDepolyTermContent"></div>
                <div class="area_newval screen_out" v-html="viewModel.model.content"></div>
              </div>
              <div v-if="viewModel.isDiffMode" class="area_diff">
                <strong class="tit_diff">문자만 비교</strong>
                <div
                  class="inner_diff"
                  v-html="viewModel.diffTextData"></div>
              </div>
              <div v-if="viewModel.isDiffMode" class="area_diff">
                <strong class="tit_diff">코드 통채로 비교</strong>
                <div
                  class="inner_diff"
                  v-html="viewModel.diffCodeData"></div>
              </div> -->
              <!-- <EditorTiptap
                :value.sync="viewModel.model.content"
                :menuDataList="['bold','heading1','heading2','heading3','bulletList','orderedList']"
                @update="viewModel.onUpdateEditor()"/> -->
            </div>
          </td>
        </tr>
        <tr>
          <th>배포 예정 일시<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <DateTimePicker
              :value.sync="viewModel.model.deploy_at"/>
          </td>
        </tr>
        <tr v-if="viewModel.isModify">
          <th>상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                badgeSize="small"
                :text="viewModel.model.status | convertIdToText('term_status')"
                :badgeStyle="viewModel.model.status | convertIdToColor('term_status')" />
            </span>
          </td>
        </tr>
      </template> 
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="SERVICE_TERM_LIST"
          :routeQuery="{ type : viewModel.model.type }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          :text="!viewModel.isModify? '배포예약' : '수정'"
          @onClickBtn="viewModel.onClickComplete()"/>
        <Button
          v-if="viewModel.isModify"
          btnSize="medium"
          btnStyle="primary_border"
          text="임시저장"/>
        <Button
          v-if="viewModel.isModify && viewModel.model.status === 'TEMP'"
          btnSize="medium"
          btnStyle="fourthly"
          text="문서삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import EditorTiptap from '@lemontree-ai/lemontree-admin-common-front/components/common/editor/EditorTiptap';
import DateTimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateTimePicker';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import TermDetailViewModel from '@/views/service/term/viewModel/TermDetailViewModel'

export default {
  name:'TermRegister',
  components:{
    PageWithLayout,
    TableView,
    SwitchCheck,
    IconSvg,
    Selectbox,
    EditorTiptap,
    DateTimePicker,
    Badge,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id
    const type = this.$route.params.type;
    this.viewModel.init(id, type);
    this.viewModel.isModify = this.$route.meta.isModify;
  },
  data(){
    return{
      viewModel: new TermDetailViewModel(),
      isDiffMode: false,
    }
  },
}
</script>
<style scoped>
.inner_td{display:flex;flex-wrap:nowrap;padding-right:10px}
.area_diff,
.area_diff + .editor{flex:1 0 50%}
.area_diff + .editor{margin-left:10px}
.editor:only-child{flex:1 1}
.editor::v-deep .inner_editor{height:100%;min-height:500px;max-height:inherit;box-sizing:border-box}

.area_diff{display:flex;flex-direction:column;box-sizing:border-box;border:3px solid #111;border-radius:8px}

.area_diff .tit_diff{display:block;padding:0 20px;font-weight:700;line-height:36px;border-bottom:3px solid #111;color:#111}
.inner_diff{flex:1 1;overflow-y:auto;border-radius:0 0 8px 8px;padding:20px;font-size:16px;line-height:24px;background-color:#eee;color:#111}
.inner_diff::v-deep h1,
.inner_diff::v-deep h2,
.inner_diff::v-deep .tit_comm,
.inner_diff::v-deep strong{font-weight:700}
.inner_diff::v-deep h1{font-size:28px;line-height:44px}
.inner_diff::v-deep h2{font-size:22px;line-height:40px}
.inner_diff::v-deep h3{font-weight:600;font-size:20px;line-height:34px}
.inner_diff::v-deep .tit_comm{font-size:20px}
.inner_diff::v-deep em{font-style:italic}
.inner_diff::v-deep a{color:#3b8aff;text-decoration:underline}
.inner_diff::v-deep img{display:block;width:calc(100% + 40px);margin:0 -20px}
.inner_diff::v-deep ul,
.inner_diff::v-deep ul > li{list-style:disc}
.inner_diff::v-deep ul{padding-left:20px;text-indent:-4px}
.inner_diff::v-deep ul > li > ul,
.inner_diff::v-deep ul > li > ul > li{list-style:circle}
.inner_diff::v-deep ul > li > ul > li > ul,
.inner_diff::v-deep ul > li > ul > li > ul > li{list-style:square}
.inner_diff::v-deep ol,
.inner_diff::v-deep ol > li{list-style:decimal}
.inner_diff::v-deep ol{padding-left:26px}
.inner_diff::v-deep ol > li > ol,
.inner_diff::v-deep ol > li > ol > li{list-style:lower-latin}
.inner_diff::v-deep ol > li > ol > li > ol,
.inner_diff::v-deep ol > li > ol > li > ol > li{list-style:lower-roman}
.inner_diff::v-deep ol > li > ol{padding-left:20px}
.inner_diff::v-deep p{min-height:24px}

.inner_diff::v-deep .txt_diff{display:inline-block;vertical-align:top}
.inner_diff::v-deep .txt_diff_remove{background-color:#f00f0020}
/* .inner_diff::v-deep .txt_diff_remove * */
.inner_diff::v-deep .txt_diff_add{background-color:#08f00020}
/* .inner_diff::v-deep .txt_diff_add * */
.inner_diff::v-deep .txt_diff_remove .txt_diff_ad,
.inner_diff::v-deep .txt_diff_remove .txt_diff_remove,
.inner_diff::v-deep .txt_diff_ad .txt_diff_remove,
.inner_diff::v-deep .txt_diff_ad .txt_diff_ad{background-color:#f00}

@media (prefers-color-scheme: dark) {
  .area_diff .tit_diff{color:#fff}
  .inner_diff{background-color:#111;color:#fff}
  .inner_diff::v-deep .txt_diff_remove,
  .inner_diff::v-deep .txt_diff_remove *{background-color:#f00f0040}
  .inner_diff::v-deep .txt_diff_add,
  .inner_diff::v-deep .txt_diff_add *{background-color:#08f00040}
}
</style>